// extracted by mini-css-extract-plugin
export var ArtistDescription = "ToddJames-module--ArtistDescription--WWZbj";
export var ArtistInfos = "ToddJames-module--ArtistInfos--Jbjfp";
export var ButtonWrapper = "ToddJames-module--ButtonWrapper --XKoND";
export var CardWrapper = "ToddJames-module--CardWrapper--KM3GS";
export var CarrouselWrapper2 = "ToddJames-module--CarrouselWrapper2--N6CDS";
export var Citations = "ToddJames-module--Citations--JdHxc";
export var DescriptionWrapper = "ToddJames-module--DescriptionWrapper--mqCOm";
export var ImageWrapper = "ToddJames-module--ImageWrapper--n6Evx";
export var LinkWrapper = "ToddJames-module--LinkWrapper--FYhDK";
export var MobileProtrait = "ToddJames-module--MobileProtrait--UF+Pw";
export var More = "ToddJames-module--More--UO-Cv";
export var MoreButton = "ToddJames-module--MoreButton--fwkef";
export var NameWrapper = "ToddJames-module--NameWrapper--8blS1";
export var PdpWrapper = "ToddJames-module--PdpWrapper--XiahB";
export var PhotosWrapper = "ToddJames-module--PhotosWrapper--pt040";
export var ProfilWrapper = "ToddJames-module--ProfilWrapper--5fopZ";
export var TitleWrapper = "ToddJames-module--TitleWrapper--awfpA";
export var Wrapper = "ToddJames-module--Wrapper--TwSWR";