import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./ToddJames.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import ToddJamesPhoto1 from "../../../../res/Photos site/Todd James/Todd James 2.jpg"
import ToddJamesPhoto2 from "../../../../res/Photos site/Todd James/todd_james.jpg"
import ToddJamesPhoto3 from "../../../../res/Photos site/Todd James/Todd James aka Reas AOK .png"
import ToddJamesPhoto4 from "../../../../res/Photos site/Todd James/Todd James graff.jpg"
import Button from "./../../../../components/Button";
import PastPresent1 from "../../../../res/james/past-present-1.jpg"
import PastPresent2 from "../../../../res/james/past-present-2.jpg"
import PastPresent3 from "../../../../res/james/past-present-3.jpg"
import Pdp from "../../../../res/james/portrait.jpg"
import { Helmet } from "react-helmet";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "ToddJames",
  name: "ToddJames",
  description:"Artiste et plasticien américain, Todd James est né en 1969 à New York où il vit et travaille. Issu de la scène Graffiti sous le nom REAS à la fin des années 1980, Todd James réalise des peintures sur toiles qui renferment la nature graphique de ses graffitis de jeunesse. Se distinguant par des aplats contrastés de couleurs vives créant des compositions figuratives vibrantes, les oeuvres de Todd James sont peuplées de nus féminins ou de femmes en petites tenues ou encore de figures plus politiques porteuses d’armes dans des environnements tropicaux. Ces oeuvres aux sujets pourtant graves diffusent un sentiment de légèreté grâce à l’utilisation d’une grande variété de couleurs électriques. Son travail reflète sa grande diversité d’expériences et partage certaines affinités avec ceux de ses amis artistes Steve Powers et Barry McGee avec lesquels il avait créé l'exposition culte Street Market au ICA de Philadelphia en 1999 puis chez Deitch projects, New York en 2000. Cette installation fut recréée comme oeuvre centrale de l’exposition Art in the Street au MOCA de Los Angeles en 2011 ensuite itinérante (Yerba Buena Center for the Arts, San Francisco, CA, USA; The Institute of Contemporary Arts, Philadelphia, PA, USA; The Geffen Contemporary, MOCA, Los Angeles, CA, USA; and Brooklyn Museum, Brooklyn, NY, USA). Son travail fait partie de nombreuses collections privées prestigieuses dont le MIMA Museum de Bruxelles, Belgique. ",
  pdp: Pdp,
  alt_pdp: "Photo de profil de ToddJames.",
  photos: [
    { src: PastPresent1, name: "ToddJames" },
    { src: PastPresent2, name: "ToddJames" },
    { src: PastPresent3, name: "ToddJames" },
  ],
  works: ["Works", "Works", "Works"],
  videos: ["Videos", "Videos", "Videos"],
  citations: []
};


const ToddJames = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>TODD JAMES <h2 style={{paddingLeft: "16px"}}>(Américain)</h2></h1>
          <h3 className={NameWrapper} >Né en 1969</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
          <ul className={ArtistInfos}>
            <p style={{fontWeight: "bolder"}}>SOLO EXHIBITIONS</p>
            <br />
            <p style={{fontWeight: "bold"}}>2022</p>
            <p>- Todd James : Clean Jellybean, Over the influence, Bangkok, Thailand 20 novembre 2021 - 10 janvier 2022</p>
            <br />
            <p style={{fontWeight: "bold"}}>2021</p>
            <p>- Todd James, Ross + Kramer, Wane One and Aindriais Dolan I'm Going Every Sunday Now Aug 26th, 2021 - Sep 12th, 2021</p>
            <br />
            <p style={{fontWeight: "bold"}}>2020</p>
            <p>- Todd James : The Intrepid, Galeria Javier Lopez & Fer Frances, Madrid, Spain, September 10th, 2020 - February 10th, 2021.</p>
            <br />
            <div className={More} id="more">
            <p style={{fontWeight: "bold"}}>2019</p>
            <p>- There’s no place like home, Over the Influence, Los Angeles, CA, September 8th - October 27th.</p>
            <p>- Todd James, Sanctuary, Galleria Patricia Armocida, Milan, Italy, March 4th - June 28th.</p>
            <br />
            <p style={{fontWeight: "bold"}}>2018</p>
            <p>- Electric Ritual, Alice Gallery, Brussels, Belgium, September 6th - October 27th.</p>
            <p>- Love To Love You, Galeria Javier Lopez & Fer Frances, Madrid, Spain, February 22nd - May 8th.</p>
            <p>- Interiors, Eighteen Gallery, Copenhagen, Denmark, January 20th - February 24th.</p>
            <br />
            <p style={{fontWeight: "bold"}}>2016</p>
            <p>- We Are One, Galeria Javier Lopez & Fer Frances, Madrid, Spain, September 15th - November 2nd.</p>
            <p>- Stay Golden, ALSHONANUZUKA, Hong Kong, November 11th - December 24th.</p>
            <br />
            <p style={{fontWeight: "bold"}}>2015</p>
            <p>- Pump Pump, Alice Gallery, Brussels, Belgium, Janvier 22nd - March 7th.</p>
            <br />
            <p style={{fontWeight: "bold"}}>2014</p>
            <p>- Afternoon Delight, AishoNanzuka, Hong Kong, September 12th - October 11th.</p>
            <p>- Secret Garden, Galleria Patricia Armocida, Milan, Italy, May 14th - August 1st.</p>
            <p>- Strange Days, Galeria Javier Lopez, Madrid, Spain, June 23rd - July 28th.</p>
            <p>- Supernatural, Sandra Gering Inc., New York, NY, February 27th - April 5th.</p>
            <br />
            <p style={{fontWeight: "bold"}}>2013</p>
            <p>- Todd James, Cooper Cole Gallery, Toronto, Canada, November 8th - December 7th.</p>
            <p>- World Domination, Lazarides Gallery, London, UK, March 6th - April 11th.</p>
            <br />
            <p style={{fontWeight: "bold"}}>2012</p>
            <p>- Free To Be You & Me, V1 Gallery, Copenhagen, Denmark, October 5th - October 27th.</p>
            <p>- Yield to Temptation, Nanzuka Underground Gallery, Tokyo, Japan, June 2nd - July 1st.</p>
            <p>- King of the Wild Frontier, Gering & Lopez Gallery, New York, NY, March 1st - April 21st.</p>
            <br />
            <p style={{fontWeight: "bold"}}>2010</p>
            <p>- Infinity Lessons, Alice Gallery, Brussels, Belgium, December 2nd, 2010 - January 29th, 2011.</p>
            <p>- Great Adventure, Galeria Javier Lopez, Madrid, Spain, September 23rd - November 10th.</p>
            <p>- Make My Burden Lighter, Gering & Lopez Gallery, New York, NY, January 15th - February 20th.</p>
            <br />
            <p style={{fontWeight: "bold"}}>2009</p>
            <p>- Don’t Stop Get it Get it, Colette, Paris, France, September 1st - October 3rd.</p>
            <p>- Double Disaster, Don’t Come Gallery, Melbourne, Australia, January 16th.</p>
            <p>- Disaster, Monster Children, Camperdown, Australia, January 15th.</p>
            <br />
            <p style={{fontWeight: "bold"}}>2008</p>
            <p>- Blood & Treasure, Lazarides Gallery, London, UK, August 29th - September 28th.</p>
            <br />
            <p style={{fontWeight: "bold"}}>2007</p>
            <p>- Todd ‘Reas’ James, Trouble, V1 Gallery, Copenhagen, Denmark, February 23rd - March 24th.</p>
            <br />
            <br />
            <p style={{fontWeight: "bolder"}}>GROUP EXHIBITIONS</p>
            <br />
            <p style={{fontWeight: "bold"}}>2022</p>
            <p>- Accrochage saisonnier, Ghost Galerie, Paris, France, Janvier-Juin 2022 Awakened Voices: A Curated Look From Around The World, Over the Influence.</p>
            <p>- Interior Dialogues, V1 Gallery, 19 Août-19 Septembre 2022.</p>
            <br />
            <p style={{fontWeight: "bold"}}>2021</p>
            <p>- See you all, Alice, Belgique, 26 novembre - 29 décembre</p>
            <p>- Paper Cut Bodega Volume III, V1 Gallery, Copenhague, 29 octobre - 30 novembre</p>
            <p>- Nos fantômes, Ghost galerie, FR, 10 Septembre - 9 octobre 2021</p>
            <p>- BEYOND THE STREETS on PAPER, Southampton Arts Center, Southampton, USA, July 17 - August 28.</p>
            <p>- POP-UP!, Galleria Patricia Armocida</p>
            <p>- I'm Going Every Sunday Now, Ross+Kramer Gallery</p>
            <p>- BEYOND THE STREETS on NTWRK, BEYOND THE STREETS</p>
            <p>- Go Figure, Over the Influence</p>
            <p>- Lampadephoria, V1 Gallery</p>
            <br />
            <p style={{fontWeight: "bold"}}>2020</p>
            <p>- Dreamer Believer, Whitestone Gallery</p>
            <p>- Warm Welcome, V1 Gallery</p>
            <br />
            <p style={{fontWeight: "bold"}}>2019</p>
            <p>- Rose Béton, La Biennale du Street Art, Les Abattoirs, Toulouse, France, September 27th, 2019 to Janvier 5th 2020</p>
            <br />
            <p style={{fontWeight: "bold"}}>2014</p>
            <p>- Paint New York - Painting from the American East Coast, Copenhagen Gallery of Contemporary Art GL Strand, Copenhagen, Denmark, June 14 th - September 7th.</p>
            <p>- KNOCK! KNOCK!, Galeria Javier Lopez, Madrid, Spain, June 26th - September 3rd.</p>
            <p>- Ok, I’ll Rename It, Billy Brandy Gallery, Kansas City, Missouri, MO, July 11th - August 9th.</p>
            <p>- Just Before Brazil, Alice Gallery, Brussels, Belgium, May 15th - June 27th.</p>
            <p>- OPENING TONIGHT - Art Truancy : Celebrating 20 Years of Juxtapoz Magazine, Jonathan Levine Gallery, New York, NY, May 15th - June 14th.</p>
            <p>- It’s an Invasion, The National Arts Club, Gramercy Park, New York, NY, May 2nd - May 10th.</p>
            <p>- Pulp, The Outsiders Gallery, Newcastle, London, UK, February 21st - March 29th.</p>
            <br />
            <p style={{fontWeight: "bold"}}>2013</p>
            <p>- Business as Usual, Galeria Javier Lopez, Madrid, Spain, September 20th - December 3rd.</p>
            <p>- Meet Your Maker, Bleecker Street Arts Club, New York, NY, October 10th - November 19th.</p>
            <p>- Chicken or Beef? A Transcontinental Survey of Figurative Painting, curated by Jesper Elg, The Hole, New York, NY, March 6th - April 20th.</p>
            <br />
            <p style={{fontWeight: "bold"}}>2012</p>
            <p>- Tonight We Won’t Be Bored. 10 Years of V1 Gallery, V1 Gallery, Copenhagen, Denmark, November 30th, 2012 - January 12th, 2013.</p>
            <p>- Beyond Elegance, Todd James + Alexis Ross, Valmorbida Gallery, New York, NY, November 13th - 30th.</p>
            <p>- Spacegrass, curated by Chris Bors, Bloom Projects, New York, NY, September 8th - October 14th.</p>
            <br />
            <p style={{fontWeight: "bold"}}>2011</p>
            <p>- Brothers of the Weird, Cooper Cole Gallery, Toronto, Ontario, Canada, November 25th, 2011 to January 22nd, 2012</p>
            <p>- Contemporaries, Galleria Patricia Armocida, Milan, Italy, September 28th - November 14th.</p>
            <p>- Pretty On The Inside, curated by Kaws and Erik, Parker Paul Kasmin Gallery, New York, NY, June 23rd - September 17th.</p>
            <p>- Art in the Streets, The Geffen Contemporary at LA MOKA - Los Angeles Museum of Contemporary Art, Los Angeles, California; traveling to Brooklyn Museum, New York, NY, April 17th - August 18th.</p>
            <p>- Facemaker, Royal/T, Los Angeles, California; curated by Kathy Greyson from The Hole, New York, NY, April 1st - June 30th.</p>
            <br />
            <p style={{fontWeight: "bold"}}>2010</p>
            <p>- Brush Strokes, with Lydia Fong aka Barry McGee, V1 Gallery, Copenhagen, Denmark, July 9th - September 4th.</p>
            <p>- Album exhibition, V1 Gallery, Copenhagen, Denmark, June 4th - July 3rd.</p>
            <br />
            <p style={{fontWeight: "bold"}}>2009</p>
            <p>- The New Yorkers, V1 Gallery, Copenhagen, Denmark, May 1st - June 22nd.</p>
            <p>- USA Today: Una aproximación al arte norteamericano del siglo XXI, curated by Alejandro and Mario Martín Pareja, organized by Contemporánea, Sala Municipal de Exposiciones de la Casa Revilla. Torrecilla, Valladolid, España, Spain, February 9th - March 15th (travelling exhibition).</p>
            <p>- The Paper Chase, O.H.W.O.W., Miami, FL; traveling to Plaything Gallery, Auckland, New Zealand.</p>
            <br />
            <p style={{fontWeight: "bold"}}>2008</p>
            <p>- The Outsiders, The Lazarides Gallery, London, UK, May 15th - June 29th.</p>
            <p>- The Destruction of Atlantis, curated by Jesper Elg, Union Gallery, London, UK, October 1st - November 29th.</p>
            <br />
            <p style={{fontWeight: "bold"}}>2006</p>
            <p>- High Math, V1 Gallery X Arkitip, with KAWS, Venice Beach, CA ; travelling to Venice, Italy, and Tokyo, Japan, May 26th - June 24th.</p>
            <br />
            <p style={{fontWeight: "bold"}}>2005</p>
            <p>- Beautiful Losers:Contemporary Art and Street Culture, Baltimore Contemporary Museum, Baltimore, Maryland; Orange County Museum of Art, Orange County, CA, July 1st - September 24th.</p>
            <br />
            <p style={{fontWeight: "bold"}}>2004</p>
            <p>- Beautiful Losers, Cincinnati Contemporary Arts Center, Cincinnati, Ohio; Yerba Buena Center for the Arts, San Francisco, California, USA, March 11th - 13th.</p>
            <br />
            <p style={{fontWeight: "bold"}}>2002</p>
            <p>- International 2002, Beloved Fiend, Liverpool Biennial, Liverpool, UK, September 14th - November 24th.</p>
            <p>- Pot o’Gold 2, PAM Gallery Australia, Perks, Australia.</p>
            <br />
            <p style={{fontWeight: "bold"}}>2001</p>
            <p>- Tits and Ass, with Jamil GS, Huston Gallery, Seattle, Washington D.C.</p>
            <p>- Pot o’Gold 2, with Chris Johanson and James Jarvis, PAM Gallery Australia, Perks, Australia.</p>
            <br />
            <p style={{fontWeight: "bold"}}>2000</p>
            <p>- Street Market , with Steve Powers and Barry McGee, Deitch Projects, New York, NY ; Parco Gallery, Nagoya, Japan; Venice Biennale, Venice, Italy.</p>
            <p>- Untitled 2000, Japan Group show Keith Haring Foundation, Tokyo, Japan.</p>
            <br />
            <p style={{fontWeight: "bold"}}>1999</p>
            <p>- Indelible Market, with Steve Powers and Barry McGee, ICA - Institute for Contemporary Art, Philadelphia, PA.</p>
            <br />
            <p style={{fontWeight: "bold"}}>1998</p>
            <p>- Daze/Crash with guest Reas, Recent Works, Palazzo Enrico Coveri Florence, Florence, Italy.</p>
            <p>- Adventures of the Four Letter Word, with Steve Powers, New York, NY.</p>
            <br />
            <br />
            <p style={{fontWeight: "bolder"}}>LITERATURE</p>
            <br />
            <p>- Yield to Temptation, Nanzuka Underground Gallery, Tokyo, Japan.</p>
            <p>- Art in the Streets, Jeffrey Deitch, Roger Gastman, Aaron Rose; MoCA - Los Angeles; Rizzoli.</p>
            <p>- Blood & Treasure, Jonathan Lethem; Lazarides Gallery, London, UK.</p>
            <p>- Mascots & Mugs. The Characters and Cartoons of Subway Graffiti, Todd James, David Villorente; introduction by Jonathan Lethem; Testify Books.</p>
            <p>- Attitude Dancer #2, Todd James; Reas International.</p>
            <p>- Beautiful Losers. Contemporary Art & Street Culture, Aaron Rose, Christian Strike, Alex Baker, Arty Nelson, Jocko Weylandtexts by Mike Mills, Barry McGee, Margaret Kilgallen, Ryan McGinness, Harmony Korine, Ed Templeton, Chris Johanson, Shepard Fairey; D.A.P./Iconoclast.</p>
            <p>- Todd James, Rachel Greene; Testify Books.</p>
            <p>- Beloved Fiend - The Art of Todd James, Tate Liverpool Biennial, Liverpool.</p>
            <p>- Street Market: Barry McGee, Stephen Powers, Todd James, Taka Kawachi; Deitch Projects, New York; Little More.</p>
            <p id="end">- Attitude Dancer, Todd James; Reas International.</p>
            </div>
          </ul>
            <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");

                
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                 
              }}
               >Read more</button>
        </div>
      </div>
      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/fr/contactez-nous";
                }
              }
            }
            >
              Contactez-nous
            </Button>
        </div>
    </div>
  );
};

export default ToddJames;